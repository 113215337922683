import { InputComponentMetadata } from "@eavfw/quickform-core";
import { JSONSchema7 } from "json-schema";
import { ProductCollectionInputProps } from "./ProductCollectionInput";
export const ProductCollectionInputSchema: InputComponentMetadata<ProductCollectionInputProps> = {
    label: "Product Collection",
    field: {
        type: "select",
        // typeProvider: (a) => a.maxItems === 1 ? "select" : "multiselect",
        listValuesProvider: (a) => Object.entries(a.options ?? {}).map(([okey, o]) => (typeof (o) === "string" ?
            {
                label: o,
                name: okey
            } : { label: o.label, name: okey }))
    },
    uiSchema: {
        paragraph: {
            "ui:widget": "textarea"
        },
        // Notice here we change the ui component from the generic quickform to our customer specific component -> ../Designer/ButtonsOptionsField
        products: {
            "ui:field": "ProductLookupField",
            "ui:multiselect":  true
        },
       
        options: {
            "ui:widget":"hidden"
        }
    },
    schema: {
        type: "object",

        properties: {
            text: {
                title: "Text",
                type: "string"
            },
            products: {
                title:"Products",
                type: "array",
                items: {
                    type: "string"
                }
            },
            useImages: {
                type: "boolean",
            },
            useDropdown: {
                type: "boolean",
            },
            options: {
                type: "object",
            }
        }
    } as JSONSchema7
}
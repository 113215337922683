"use client";
import { ChangeEvent, useRef, useState } from "react";
import { makeStyles, shorthands } from '@griffel/react';
import { quickformtokens } from "@eavfw/quickform-core";

const useDawaAddressStyles = makeStyles({
    inputText: {
        backgroundColor: 'transparent',
        border: 'none',
        ...shorthands.borderBottom("1px", "solid", quickformtokens.primary),
        fontSize: quickformtokens.questionInputFontSize,
        color: quickformtokens.onSurface,
        marginTop: '8px',
        paddingBottom: '9px',
        width: '100%',
        ...shorthands.transition(
            "color,opacity",
            "1s",
            "0ms",
            "ease"
        ),
        '-webkit-appearance': 'none',
        '&::-webkit-search-cancel-button': {
            '-webkit-appearance': 'none',
        },

        ':focus-visible': {
            ...shorthands.borderBottom("2px", "solid", quickformtokens.primary),
            paddingBottom: '8px',
            outline: 'none',
            '::placeholder': {
                color: quickformtokens.onSurface,
                opacity: 1,
            },
        },

        '::placeholder': {
            color: quickformtokens.questionPlaceholderColor,
            opacity: quickformtokens.mediumEmphasisOpacity,
            ...shorthands.transition(
                "color",
                "1s"
            ),
        },

        '@media screen and (max-width: 599px)': {
            fontSize: quickformtokens.multilineTextMobileFontSize,
            marginTop: '32px',
        },
    },
    listbox: {
        maxHeight: "250px",
        backgroundColor: quickformtokens.surface,
        padding: "0",
        textAlign: "left",
        borderRadius: "0.3125em",
        boxShadow: "0 0.0625em 0.15625em rgba(0,0,0,0.15)",
        position: "absolute",
        left: "0",
        right: "0",
        zIndex: 2000000,
        overflowY: "auto",
        boxSizing: "border-box",
    },
    listItem: {
        margin: "0",
        listStyle: "none",
        cursor: "pointer",
        padding: "0.4em 0.6em",
        border: "0.0625em solid #ddd",
    },
    expandItem: {
        margin: "0",
        listStyle: "none",
        cursor: "pointer",
        padding: "0.4em 0.6em",
        border: "0.0625em solid #ddd",
        ...shorthands.borderWidth("0.0625em"),
        color: quickformtokens.questionPlaceholderColor
    },
    clearButton: {
        ...shorthands.border('none'),
        backgroundColor: 'transparent',
        cursor: 'pointer',
        position: 'absolute',
        right: '0px',
        top: '50%',
        transform: 'translateY(-50%)',
        fontSize: '24px',
        color: quickformtokens.primary
    },

});

export type DawaLocation = { type: string, tekst: string, caretpos: string, forslagstekst: string, data: any };
export type Location = { key: string, title?: string, location?: string, isUserInput: boolean };
type Props = {
    title: string;
    location: Location;
    isLoaded: boolean;
    handleLocationSet: (location: Location) => void;
}
export function DawaAddressInput({ title, location, handleLocationSet }: Props) {
    const styles = useDawaAddressStyles();
    const inputRef = useRef<HTMLInputElement>(null);
    const [text, setText] = useState(location.location ?? "");
    const [addressInFocus, setAddressInFocus] = useState(false);
    const [addressOptions, setAddressOptions] = useState<DawaLocation[]>([]);
    const [slicedAddressOptions, setSlicedAddressOptions] = useState<DawaLocation[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const numAddresses = 100;
    const [expandedCount, setExpandedCount] = useState(1);

    const getAddressesAsync = async (address: string) => {
        setIsLoading(true);
        const url = `https://api.dataforsyningen.dk/autocomplete?q=${address}&fuzzy=&per_side=${numAddresses}`;
        const res = await fetch(url);
        const data = await res.json();
        const mappedData: DawaLocation[] = data.map((item: DawaLocation) => item);

        return mappedData;
    };

    const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const text = event.target.value;
        setText(text);
        setExpandedCount(1);

        setAddressInFocus(text.length > 0);

        const mappedData = await getAddressesAsync(text).then((d) => { setIsLoading(false); return d });
        setAddressOptions(mappedData);
        setSlicedAddressOptions(mappedData.slice(0, 10));
    };

    const handleAddressSelected = async (dawaLocation: DawaLocation) => {
        setText(dawaLocation.tekst);
        handleLocationSet({ ...location, location: dawaLocation.tekst });

        const mappedData = await getAddressesAsync(dawaLocation.tekst).then((d) => { setIsLoading(false); return d });
        setAddressOptions(mappedData);
        setSlicedAddressOptions(mappedData.slice(0, 10));

        // If type is adresse, address specifications are exhausted therefor force close the address options else reopen
        if (dawaLocation.type === "adresse")
            setAddressInFocus(false);
        else {
            inputRef?.current?.focus();
            setAddressInFocus(true);
        }
    };

    const handleInputBlur = () => { if (!isLoading) (setAddressInFocus(false)) };
    const handleInputFocus = () => setAddressInFocus(true);

    const handleExpand = () => {
        setExpandedCount(expandedCount + 1);
        setSlicedAddressOptions(addressOptions.slice(0, 10 * (expandedCount + 1)));
    }

    return (
        <div style={{ position: "relative", paddingBottom: "32px" }}>
            <>{title}</>
            <input
                type="search"
                ref={inputRef}
                className={styles.inputText}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                value={text}
                onChange={handleChange}
                disabled={!location.isUserInput}
                placeholder={"Indtast adresse"}
            />
            {text && (
                <button
                    onClick={() => setText('')}
                    className={styles.clearButton}
                    aria-label="Clear text"
                >
                    &#x2715; {/* Unicode Cross Mark */}
                </button>
            )}
            <div hidden={!addressInFocus}>
                <ul role={"listbox"} className={styles.listbox}>
                    {slicedAddressOptions.map((o, i) => (
                        <li key={i}
                            onMouseDown={e => e.preventDefault()}
                            className={styles.listItem}
                            style={{ borderBottomWidth: i === addressOptions.length - 1 ? "0.0625em" : "0" }}
                            onClick={() => handleAddressSelected(o)}>
                            {o.tekst}
                        </li>
                    ))}
                    {addressOptions.length > 10 * expandedCount &&
                        <li onMouseDown={e => e.preventDefault()}
                            className={styles.expandItem}
                            onClick={handleExpand}>
                            ...
                        </li>
                    }
                </ul>
            </div>
        </div>
    );
};
import { ArrowUpIcon } from "./ArrowUpIcon";
import { BuildingIcon } from "./BuildingIcon";
import { CityIcon } from "./CityIcon";
import { EmailIcon } from "./EmailIcon";
import { HomeIcon } from "./HomeIcon";
import { InfoIcon } from "./InfoIcon";
import { MagnifyingGlassIcon } from "./MagnifyingGlass";
import { MapMakerIcon } from "./MapMaker";
import { TelephoneIcon } from "./TelephoneIcon";
import { UserIcon } from "./UserIcon";
import { IconProps } from "./iconProps";

export type IconType = "Email" | "Phone" | "User" | "Info" | "MagnifyingGlass" | "UpArrow" | "MapMaker"|"Home"|"Building"|"City";
export type IconResolverProps = {
    type: IconType,
} & IconProps

export const IconResolver: React.FC<IconResolverProps> = ({ type, color, className, size }) => {
    switch (type) {
        case "Email": return <EmailIcon className={className} color={color} size={size} />
        case "Phone": return <TelephoneIcon className={className} color={color} size={size} />
        case "User": return <UserIcon className={className} color={color} size={size} />
        case "Info": return <InfoIcon className={className} color={color} size={size} />
        case "MagnifyingGlass": return <MagnifyingGlassIcon className={className} color={color} size={size} />
        case "UpArrow": return <ArrowUpIcon className={className} color={color} />
        case "MapMaker": return <MapMakerIcon className={className} color={color} size={size} />
        case "Home": return <HomeIcon className={className} color={color} size={size} />
        case "Building": return <BuildingIcon className={className} color={color} size={size} />
        case "City": return <CityIcon className={className} color={color} size={size} />
        default: return <UserIcon className={className} color={color} size={size} />
    }
}
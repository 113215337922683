import { InputComponentType, InputProps, registerInputComponent } from "@eavfw/quickform-core";
import { ProductOptionInputSchema } from "./ProductOptionInputSchema";
import { ButtonsInput, ButtonsProperties } from "../../buttons-input/ButtonsInput";

//The options part is inserted by the backend upon save from the product id
export type ProductOptionInputProps = {
    inputType: "product-option-input";
    productQuestion: string;
} & Omit<ButtonsProperties, "inputType">;



export const ProductOptionInput: InputComponentType<ProductOptionInputProps> = ({ inputType, ...props }) => {
    //CONVERT product to options
    const buttonProps = props as Omit<typeof props, "inputType">;
    console.log("ProductInput", [props]);
    return (//@ts-ignore
        <ButtonsInput  {...buttonProps} inputType="buttons" />
    )
}

ProductOptionInput.inputSchema = ProductOptionInputSchema;
registerInputComponent("product-option-input", ProductOptionInput);
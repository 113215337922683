"use client";
import { InputComponentType, InputProps, registerInputComponent } from "@eavfw/quickform-core";
import { ProductInputSchema } from "./ProductInputSchema";
import { ButtonsInput, ButtonsProperties } from "../../buttons-input/ButtonsInput";

//The options part is inserted by the backend upon save from the product id
export type ProductInputProps = {
    inputType: "product-input";
    productid: string;   
} & Omit< ButtonsProperties, "inputType" >;



export const ProductInput: InputComponentType<ProductInputProps> = ({ productid, inputType, ...props }) => {
    //CONVERT product to options
    const buttonProps = props as Omit<typeof props, "inputType">;
    console.log("ProductInput", [props]);
    return (//@ts-ignore
        <ButtonsInput  {...buttonProps} inputType="buttons" />
    )
}

ProductInput.inputSchema = ProductInputSchema;
registerInputComponent("product-input", ProductInput);
"use client";
import { InputComponentType, InputProps, quickformtokens, registerInputComponent, useQuickForm } from "@eavfw/quickform-core";
import { ProductCollectionInputSchema } from "./ProductCollectionInputSchema";
import { ButtonsInput, ButtonsProperties } from "../../buttons-input/ButtonsInput";
import { makeStyles } from "@griffel/react";
import { mergeClasses, shorthands } from "@fluentui/react-components";
import { useState } from "react";

//The options part is inserted by the backend upon save from the product id
export type ProductCollectionInputProps = {
    inputType: "product-collection-input";
    products: Array<string>;   
    useImages: boolean;
    useDropdown: boolean;
    options: {
        [key: string]: {
            key: string;
            label: string;
            imageUrl: string;
            imageHash: string;
        }
    }
} & Omit< ButtonsProperties, "inputType" >;


export type ImageGridProperties = {
    inputType: "image-grid";
    options: {
        [key: string]: {
            key: string;
            label: string;
            imageUrl: string;
            imageHash: string;
        }
    }
    defaultValue?: string;
}
const useImageGridStyles = makeStyles({
    grid: {
            display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(120px, 1fr))',
            gap: "5px",
    },
    gridElement:{
        width:"100%",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        marginTop: "5px",
        background: "#FFF",
        border: "1px solid #ccc",
   //     height:"150px",
        position:"relative"

    },
    img: {
        maxHeight: "100%",
        maxWidth: "100%",
      //  position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: "auto",
    },
    selected: {

    },
    label: {
        height: "55px",
        minHeight: "55px",
        backgroundColor: `${quickformtokens?.surface ?? "grey"}`,
        color: `${quickformtokens?.onSurface ?? "white"}`,
        fontSize: '1rem',
        cursor: 'pointer', 
        flexGrow: 0,
        flexBasis: "100%",
        width: 'calc(100% - 1rem)',
        margin: '0.5rem',
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
        ...shorthands.transition(
            "background, color, border, border-color, box-shadow",
            "350ms",
            "ease-in-out",
            "0ms"
        ),
        ...shorthands.border("1px", "solid", "grey"),
        borderRadius: "5px",
    },
    selectedlabel: {
        backgroundColor: `${quickformtokens?.primary ?? "grey"}`,
        color: `${quickformtokens?.onPrimary ?? "white"}`,
    }
});
const ImageGrid: InputComponentType<ImageGridProperties> = ({ options, questionModel }) => {
    const { answerQuestion } = useQuickForm();
    const styles = useImageGridStyles();
    const [selectedValue, setSelectedValue] = useState<string>(questionModel.output);
    const handleChange = (value: string) => {
        setSelectedValue(value);
        answerQuestion(questionModel.logicalName, value);
    };
    return (
        <div className={styles.grid}>
            {Object.entries(options).map(([key, value]) => (
                <div  key={`button-key-${key}`}
                    onClick={() => handleChange(key)}
                    className={mergeClasses(styles.gridElement, selectedValue === key ? styles.selected : '')}
                    id={`${questionModel.logicalName}-${value.key}`}>
                    <img className={styles.img} src={value.imageUrl}></img>
                    <button className={mergeClasses(styles.label, selectedValue === key ? styles.selectedlabel : '')}>{value.label}</button>
                </div>
            ))}
        </div>
    )
}

export const ProductCollectionInput: InputComponentType<ProductCollectionInputProps> = ({ products, inputType, useImages, ...props }) => {
    //CONVERT product to options
    const buttonProps = props as Omit<typeof props, "inputType">;
    console.log("ProductInput", [props]);

    if (useImages) {//@ts-ignore
        return <ImageGrid  {...buttonProps} inputType="image-grid" />
    }

    return (//@ts-ignore
        <ButtonsInput  {...buttonProps} inputType="buttons" />
    )
}

ProductCollectionInput.inputSchema = ProductCollectionInputSchema;
registerInputComponent("product-collection-input", ProductCollectionInput);